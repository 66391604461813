const ApiConfig = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  // BASE_URL: 'https://dev.api.egaliti.com',
  // BASE_URL: 'https://egaliti-be.herokuapp.com',
  /// *** Customer  *** ///
  GET_CUSTOMER: (id) => `customers/${id}`,
  GET_CUSTOMERS: 'customers',
  APPROVE_CUSTOMER: (id) => `customers/approve/${id}`,
  DECLINE_CUSTOMER: (id) => `customers/decline/${id}`,
  FREEZE_CUSTOMER: (id) => `customers/freeze/${id}`,
  OPEN_CUSTOMER: (id) => `customers/open/${id}`,
  UPDATE_CUSTOMER: (id) => `customers/${id}`,

  /// *** Business  *** ///
  GET_BUSINESS: (id) => `businesses/${id}`,
  GET_BUSINESSES: (query) => `businesses/all${toQuery(query)}`,
  APPROVE_BUSINESS: (id) => `businesses/approve/${id}`,
  DECLINE_BUSINESS: (id) => `businesses/decline/${id}`,
  CLOSE_BUSINESS: (id) => `businesses/close/${id}`,
  OPEN_BUSINESS: (id) => `businesses/open/${id}`,
  HIDE_BUSINESS: (id) => `businesses/hide/${id}`,
  UPDATE_BUSINESS: (id) => `businesses/${id}`,
  GET_SIC_CODES_LIST: () => `sic-codes`,

  /// *** Payees *** ///
  GET_PAYEES: (query) => `payees${toQuery(query)}`,
  GET_PAYEE: (id) => `payees/${id}`,
  // Cusotmer Payees //
  GET_CUSTOMER_PAYEE: (payeeId) => `payees/customer/${payeeId}`,
  // GET_CUSTOMER_PAYEES: 'payees/customer',
  GET_BUSINESS_PAYEE: ({ businessId, payeeId }) => `payees/business/${businessId}/${payeeId}`,
  // GET_BUSINESS_PAYEES: 'payees/business',
  APPROVE_PAYEE: (payeeId) => `payees/approve/${payeeId}`,
  DECLINE_PAYEE: (payeeId) => `payees/decline/${payeeId}`,
  CLOSE_PAYEE: (payeeId) => `payees/close/${payeeId}`,
  OPEN_PAYEE: (payeeId) => `payees/open/${payeeId}`,
  FREEZE_PAYEE: (payeeId) => `payees/freeze/${payeeId}`,

  // --------------------------------------//

  /// ***  Accounts *** ///
  GET_ACCOUNTS: (query) => `accounts${toQuery(query)}`,
  GET_ACCOUNT: (id) => `accounts/${id}`,

  // -- Customer Accounts -- //
  // GET_CUSTOMER_ACCOUNT: ({ accountId }) => `accounts/customer/${accountId}`,
  // GET_CUSTOMER_ACCOUNTS: 'accounts/customer',
  // GET_BUSINESS_ACCOUNT: ({ businessId, accountId }) => `accounts/business/${businessId}/${accountId}`,
  // GET_BUSINESS_ACCOUNTS: ({ businessId }) => `accounts/business/${businessId}`,

  CLOSE_ACCOUNT: (accountId) => `accounts/close/${accountId}`,
  FREEZE_ACCOUNT: (accountId) => `accounts/freeze/${accountId}`,
  HIDE_ACCOUNT: (accountId) => `accounts/hide/${accountId}`,
  OPEN_ACCOUNT: (accountId) => `accounts/open/${accountId}`,

  // --------------------------
  /// Chat
  GET_DISCUSSIONS: (query) => `discussions${toQuery(query)}`,
  GET_DISCUSSION: (id) => `discussions/${id}`,
  GET_MESSAGES: (id, query) => `discussions/${id}/messages${toQuery(query)}`,
  CREATE_MESSAGE: (id) => `discussions/${id}/send-message`,
  CREATE_DISCUSSION: 'discussions/enquiry',

  /// Notes
  GET_NOTES: (query) => `notes${toQuery(query)}`,
  CREATE_NOTE: 'notes',

  /// Transactions
  GET_TRANSACTIONS: (accountId, query) => `transactions/${accountId}${toQuery(query)}`,
  GET_CUSTOMER_TRANSACTIONS: (query) => `transactions/customer${toQuery(query)}`, // new
  GET_BUSINESS_TRANSACTIONS: (businessID, query) => `transactions/business/${businessID}${toQuery(query)}`, // new
  GET_ALL_TRANSACTiONS: (query) => `transactions/${toQuery(query)}`, // new

  // compliance
  GET_TM_PATTERNS: () => `compliance/tm-patterns`,
  GET_TM_PATTERN_DATA: (patternId, query) => `compliance/tm-patterns/${patternId}${toQuery(query)}`,
  EXPORT_COMPLIANCE: (patternId, query) => `compliance/tm-patterns/${patternId}/exports${toQuery(query)}`,

  /// Files
  GET_FILE: (path) => `files/${path}`,
  UPLOAD_FILE: 'sls-files',

  // NEW FILES
  EXPORTS_MMANAGER: (query) => `exports-manager${toQuery(query)}`,
  DOWNLOAD_FILE: (query) => `sls-files/download${toQuery(query)}`,
  DELETE_FILE: () => `sls-files`,


  /// MLRO
  GET_MLRO: '',

  /// Auth
  VERIFY_MLRO_AUTH: 'is-mlro-auth',
};

function toQuery(queryObj) {
  if (!queryObj || !Object.keys(queryObj).length) return '';
  const queries = [];
  Object.keys(queryObj).forEach((key) => {
    if (queryObj[key]) {
      queries.push(`${key}=${queryObj[key]}`);
    }
  });
  return `?${queries.join('&')}`;
}

export default ApiConfig;
