import { createSlice } from '@reduxjs/toolkit';
// utils
import API from '../../utils/apiConfig';
import { post, get, update } from '../../utils/server';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const actionsNames = {
  approve: 'approve',
  decline: 'decline',
  open: 'open',
  freeze: 'freeze',
  updating: "updating"
};

const initialState = {
  isLoading: false,
  error: null,
  customers: [],
  customer: null,
  sortBy: null,
  actionsOn: [],
  alert: {
    show: false,
    title: 'Alert',
    messsage: '',
    type: 'error',
  },
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Customers
    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
    },

    // GET Customer
    getCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    },

    /// Actions for Customer
    approveCustomerSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Customer Successfully Approved', type: 'success' };
      state.customer = { ...state.customer, mlroStatus: 'APPROVED' };
    },

    declineCustomerSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Customer Successfully Declined', type: 'success' };
      state.customer = { ...state.customer, mlroStatus: 'DECLINED' };
    },

    freezeCustomerSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Customer Successfully Frozen', type: 'success' };
      state.customer = { ...state.customer, status: 'FROZEN' };
    },
    openCustomerSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Customer Successfully Opened', type: 'success' };
      state.customer = { ...state.customer, status: 'OPEN' };
    },
    updateCustomerSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Customer Successfully Updated', type: 'success' };
      state.customer = { ...state.customer };
    },

    actionFailed(state, action) {
      state.alert = { show: true, title: 'Failed', messsage: action.payload.errors[0].message, type: 'error' };
    },

    // Actions started
    actionStarted(state, action) {
      state.actionsOn = [...state.actionsOn, action.payload];
    },
    actionEnd(state, action) {
      state.actionsOn = state.actionsOn.filter((actionType) => actionType !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_CUSTOMERS);
      dispatch(slice.actions.getCustomersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomer(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_CUSTOMER(id));
      dispatch(slice.actions.getCustomerSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Actions for Customer
export function approveCustomer(id, callBack, data) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.approve));
    try {
      const response = await post({ path: API.APPROVE_CUSTOMER(id), data });
      callBack('Customer Approved Successfuly', 'success');
      dispatch(slice.actions.approveCustomerSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.approve));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.approve));
    }
  };
}

export function declineCustomer(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.decline));
    try {
      const response = await post({ path: API.DECLINE_CUSTOMER(id) });
      dispatch(slice.actions.declineCustomerSuccess(response.data));
      callBack('Customer Declined Successfuly', 'success');
      dispatch(slice.actions.actionEnd(actionsNames.decline));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.decline));
    }
  };
}

export function freezeCustomer(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.freeze));
    try {
      const response = await post({ path: API.FREEZE_CUSTOMER(id) });
      callBack('Customer Frozen Successfully ', 'success');
      dispatch(slice.actions.freezeCustomerSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.freeze));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.freeze));
    }
  };
}

export function openCustomer(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.open));

    try {
      const response = await post({ path: API.OPEN_CUSTOMER(id) });
      callBack('Customer Opened Successfuly', 'success');
      dispatch(slice.actions.openCustomerSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    }
  };
}

export function updateCustomer(data, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.updating));

    try {
      const response = await update({ path: API.UPDATE_CUSTOMER(data.id), data });
      callBack('Customer Updated Successfuly', 'success', true);
      dispatch(slice.actions.updateCustomerSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.updating));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.updating));
    }
  };
}